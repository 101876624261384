import { FC } from 'react'
import { makeStyles } from '../../core/utils/theme'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
  },
}))

interface IProps {
  title?: string
  columns: string[]
  data: (string | number)[][]
}
const DataViewModalTable: FC<IProps> = ({ title, columns, data }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className="justify-center">
        <div className="rounded p-6">
          <h1 className="text-center text-xl font-semi-bold">{title}</h1>

          <div className="relative overflow-y-auto flex justify-center">
            <table className="mt-4 text-left text-sm text-gray-500 rtl:text-right grid grid-cols-2 gap-2">
              {columns.map((key: string, index: number) => (
                <tr className="flex gap-4 border-b" key={key}>
                  <th
                    scope="row"
                    className="whitespace-nowrap rounded px-6 py-4 font-medium text-gray-900 bg-transparent border-none
                    min-w-[270px] max-w-[270px]  overflow-hidden overflow-ellipsis mr-4"
                    title={key}
                  >
                    {key}
                  </th>
                  <td className="px-6 py-4 text-black border-none
                  min-w-[100px] max-w-[100px] whitespace-nowrap overflow-hidden overflow-ellipsis"
                    title={data[0][index].toString()}>{data[0][index]}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DataViewModalTable
