import { Box, Button, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useLayoutEffect, MouseEvent/*, useEffect*/ } from 'react'
import theme, { makeStyles } from '../../../core/utils/theme'
import useApp from '../../../hooks/useApp'
import useSettings from '../../../hooks/useSettings'
import useUser from '../../../hooks/useUser'
import Loader from '../../misc/Loader'
import ActionLog from './ActionLog'
import AnswerSection from './AnswerSection'
import QuestionField from './QuestionField'
// import { error } from '../../../core/services/alerts'
import { hasConfirmationFlagInUrl, isEndUserView } from '../../../core/utils/main'
// Icon
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RedirectionConfirmationModal from '../modals/RedirectionConfirmationModal'
import GuidedTourModal from '../modals/GuidedTourModal'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    minWidth:'600px',
    marginLeft:'20px',
    marginRight:'20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(0),
  },
  picker: {
    color:'grey',
    '&:hover':{
      color:'black',
      cursor:'pointer'
    }
  },
  answerSectionWrapper: {
    width: '100%',
  },
  thinkingLoaderWrapper: {
    width: '100%',
  },
  thinkingLoader: {
    margin: '8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
  },
  thinkingLoaderText: {
    marginBottom: '8px',
    textAlign: 'center',
  },
  thinkingLoaderSpinner: {
    display: 'block',
    width: '10rem',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  questionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    height: '100%',
    marginTop: '1.2rem',
  },
  sampleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.5rem',
  },
  centralLabelContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '15%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: '1%',
    },
  },
  sampleQuestionLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2%',
  },
  indicatorsContainer: {
    display: 'none',
  },
  dropdownIndicator: {
    display: 'none',
  }
}))

const MainContent: FC = observer(() => {
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isHugeScreen = !useMediaQuery(theme.breakpoints.down('lg'))

  const confirmation: boolean = hasConfirmationFlagInUrl();
  const showSaveToDashboard = app.answerText && app.queryId && (!isEndUserView() || app.cakeId=='nhanes_fd' || user.isInsider())
  const isSaved = app.dashboardItems.map(x=>x.query_id).includes(app.queryId as string)
  // let shouldOpenSettingsModal: boolean = false

  // useEffect(() => {
  //   shouldOpenSettingsModal = settings.hasDataSources
  // }, [settings.hasDataSources])
  
  useLayoutEffect(() => {
    if (app.cakeId)
      settings
        .loadSampleQuestions(app.cakeId)
        .then(() => { })
        .catch((e) => console.log('Error in loading sample questions', e))
  }, [])

  const handleSaveToDashboard = (e: MouseEvent) => {
    if (isSaved)
      return
    e.preventDefault()
    e.stopPropagation()
    if (app.queryId)
      app.saveToDashboard(app.cakeId, app.queryId, e.target as HTMLElement)
  }

  const handleBake = () => {
    settings.hasDataSources
      ? settings.setIsCreateDataRecipeModalOpen(true)
      : settings.setIsDataSourcesModalOpen(true)
  }
  
  const handleDashboardClick = () => {
    navigate(isEndUserView() ? `/dashboard?cakeId=${app.cakeId}` : '/dashboard' )
  }

  return (
    <>
      <RedirectionConfirmationModal open={confirmation} onClose={() => { }} />
      <GuidedTourModal />
      {/* <ShareModal open={settings.isShareModalOpen} onClose={() => { settings.setIsShareModalOpen(false) }} /> */}
      <Box
        className={`MAINCONTENT ${classes.root} flex-1 relative`}
      >
        <Box sx={{width:'100%', textAlign:'center'}}>
          <Tooltip title="View Dashboard">
            <span className={classes.picker} onClick={handleDashboardClick}>
              <DashboardIcon /> Dashboard
            </span>
          </Tooltip>
          &nbsp; &nbsp; | &nbsp; &nbsp; 
          <ChatIcon /> <b>Ask Cubie</b>
        </Box>

        <Box className={`flex flex-col h-full mx-auto w-full ${isTablet && !isEndUserView() && 'lg:w-full'}`}>
          <QuestionField
            placeholder="Ask a question about your data..."
            autoFocus
            onFinish={(value: string) => {
              app.setInput(value)
              app.obtainAnswer()
            }}
            disabled={app.isThinking}
          />

          {!settings.isLoading && !isEndUserView() && !app.cakeId && (
            <Box className='min-h-40 flex items-center justify-center'>
              <Button className="rounded w-[300px] bg-black px-4 py-1.5 text-white hover:bg-black" onClick={handleBake}>
                + Bake your first Datacake
              </Button>
            </Box>
          )}
{/* 
          {!app.isThinking &&
            app.sampleQuestionVisible &&
            (!isEndUserView() || isMobile || isTablet) &&
            settings.sampleQuestions.length > 0 && (
              <SampleQuestions
                currentCakeId={app.cakeId}
                sampleQuestionList={settings.sampleQuestions}
                handleOnClick={(question: string) => {
                  if (app.isThinking) {
                    error('Please wait until the previous question is answered or canceled')
                    return
                  }
                  if (app.isInitializing) {
                    error('Please wait until tables are loaded')
                    return
                  }
                  app.setInput(question)
                  app.obtainAnswer()
                }}
              />
            )} */}
              {app.isThinking && (
                <Box className={classes.thinkingLoader}>
                  <Loader className={classes.thinkingLoaderSpinner} />
                </Box>
              )}
              <ActionLog />
              {showSaveToDashboard && 
              <div className="text-right text-xs text-black mb-2">
                <span style={{cursor:isSaved ? '' : 'pointer', border:"1px solid black", padding:'2px', margin:'2px 6px 50px 0'}} onClick={(e)=>handleSaveToDashboard(e)}>{isSaved ? 'Saved' : 'Save to Dashboard'}</span>
              </div>
              }

              <Box
                className={`${classes.answerSectionWrapper} px-[1.25rem]`}
                sx={[
                  !app.isThinking && {
                    flexGrow: 1,
                    overflowY: 'auto',
                  },
                  app.isActionLogOpenedInNewWindow && {
                    height: { xs: 'auto', sm: '100%' },
                  },
                  app.isThinking && {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }
                ]}
              >

            {!app.isThinking && app.sampleQuestionVisible && isEndUserView() && settings.sampleQuestions?.length > 0 && isHugeScreen && (
              <Box className={classes.centralLabelContainer}>
                <Box className={classes.sampleQuestionLabelContainer}>
                  <Typography sx={{ marginRight: '1rem', textAlign: 'center', fontSize: isMobile ? 24 : 34 }}>
                    Start by selecting a sample question
                  </Typography>
                  {!isMobile && !isTablet && <FontAwesomeIcon icon={faArrowRight} size={'3x'} />}
                </Box>
                <Typography sx={{ marginRight: '1rem', textAlign: 'center', fontSize: isMobile ? 24 : 34 }}>
                  or type your own
                </Typography>
              </Box>
            )}
            <Box className={classes.thinkingLoaderWrapper}>
              {app.answerText && (
                <>
                
                <AnswerSection
                  answerStr={app.answerText}
                  answerData={app.answerData}
                  answerChartData={app.answerChartData}
                  answerChartHtml={app.answerChartHtml}
                />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
})
export default MainContent
