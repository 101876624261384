import { FC } from 'react'
import { IDataSample } from '../../../core/types/code_service/IDataObject'
import ContentModal from './ContentModal'
import DataViewModalTable from '../../misc/DataViewModalTable'

interface IProps {
  head: IDataSample
  open: boolean
  title: string
  onClose: () => void
}
const DataViewModal: FC<IProps> = ({ head, open = false, onClose, title }) => {
  return (
    <ContentModal open={open} onClose={onClose} title={title}>
      <DataViewModalTable title={title} columns={head.columns} data={head.data} />
    </ContentModal>
  )
}

export default DataViewModal
