import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import theme, { makeStyles } from '../../core/utils/theme'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../misc/Navbar'
import useSettings from '../../hooks/useSettings'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import SearchField from '../main/content/SearchField'
import { MouseEvent } from 'react'
import { observer } from 'mobx-react'
import MktDatasetDetailsModal from '../main/modals/DataMarket/MktDatasetDetailsModal'
import {getFakeCakeMetadatas} from '../misc/FakeDataBrowseDatasets'
import { IMktDatasetInfo } from '../../core/types/source_service/IMktDatasetInfo'

const useStyles = makeStyles()((theme) => ({
  root: {
    height:'100vh',
    width: '100%',
    display:'flex',
    flexFlow:'column'
  },
  mainContainer: {
    marginX: '0',
    padding: '0 10px',
    flex: '1',
    display:'flex',
    flexDirection:'column'
  },
  title: {
    marginTop: '5px',
    marginBottom: '10px'
  },
  backlink: {
    marginLeft: '14px',
    position:'absolute',
    top:'0.5rem',
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding:'0',
      left:'0'
    }
  },
  searchTypeContainer: {
    placeContent: 'center',
    width: '100%',
  },
  searchType: {
    margin: '0 50px',
    padding: '5px',
    display: 'inline-block',
    align: 'center',
  },
  datasetListing: {
    padding: '5px',
    '&:hover': {
      background:'lightgrey'
    }
  },
  buttonSection: {

  }
}))

const DataMarket: FC = observer(() => {
  const navigator = useNavigate()
  const settings = useSettings()
  // const app = useApp()
  const { classes } = useStyles()

  // const [searchType, setSearchType] = useState('')
  const [isMktDatasetDetailsModalOpen, setIsMktDatasetDetailsModalOpen] = useState(false)
  const [selectedDataset, setSelectedDataset] = useState<IMktDatasetInfo>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  // let cakesList:IMktDatasetInfo[] = []

  useEffect(() => {
    // if (!settings.mktDatasets || settings.mktDatasets.length == 0)
    //   setTimeout(()=> {
    //     cakesList = getFakeCakeMetadatas()
    //     settings.setMktDatasets(cakesList)
    //   }, 2000)
    settings.setMktDatasets(getFakeCakeMetadatas())
  }, [])

  // const handleSearchTypeClick = (e: MouseEvent<HTMLElement>, value: string) => {
  //   console.log(e)
  //   setSearchType(value)
  // }
  // const handleSearch = (value: string) => {
  //   console.log(value) 
  //   setTimeout(()=> {
  //     settings.setMktDatasets(getFakeCakeMetadatas())
  //   }, 7000)
  // }

  const handleDatasetClick = (e: MouseEvent, value: string) => {
    setSelectedDataset(settings.mktDatasetsDict[value as keyof object] as IMktDatasetInfo)
    e.stopPropagation()
    e.preventDefault()
    setIsMktDatasetDetailsModalOpen(true)
  }

  return (
    <div className={classes.root}>
      <Navbar />
      <Box className="relative">
        <Button variant="text" className={`${classes.backlink} text-primary cursor-pointer`} onClick={() => { settings.setIsDataSourcesModalOpen(true); navigator('/');}}>
          {isMobile && <KeyboardArrowLeftIcon
            className="border border-black cursor-pointer"
          />}
          {!isMobile && <Typography variant='button' className="cursor-pointer">&lt; Back</Typography>}
        </Button>
      </Box>

      <Box className={classes.mainContainer}>
        <Typography className={classes.title} variant='h4' align='center'>Data Marketplace</Typography>
        <Typography align='center' className='border-t border-b border-black py-2'>Find datasets that unlock the amazing insights hidden in your data.</Typography>
        <Box sx={{/*borderTop:'1px solid black',*/ marginTop:'10px', flex:'1', display:'flex', flexFlow:'column'}}>
        {/*  <Box className={`${classes.searchTypeContainer} grid grid-cols-2 px-20`}>
            <div className={classes.searchType}>
              <a onClick={(e) => handleSearchTypeClick(e, 'search')}>
                <Typography variant='h6' align='center' sx={{background:'white', color: 'black', cursor:'pointer'}} >AI-Assisted Search w/ SignalFinder</Typography>
              </a>
            </div>
            <div className={classes.searchType}>
              <a onClick={(e) => handleSearchTypeClick(e, 'browse')}>
              <Typography variant='h6' align='center' sx={{background:'white', color: 'black', cursor:'pointer'}} >...or Just Browse</Typography>
              </a>
            </div>
          </Box> */}
          <Box sx={{/*borderTop:'1px solid black',*/ placeItems:'center', flex:'1 1 auto', padding:'10px'}}>
            {/* { searchType == 'search' && <>
              <SearchField
                sx={{width:'100%'}}
                placeholder="What data are you searching for?"
                autoFocus
                onFinish={(value: string) => handleSearch(value)}
              />
              <Typography>Signal Finder explores the marketplace of datasets looking for ones that can unlock meaningful insights when joined with your data.</Typography>
            </>} */}
            {/* { searchType == 'browse' &&  */}
              <>
              {/* <Typography align='center'>Datasets on Datacakes Marketplace</Typography> */}
              <Grid container columns={isMobile ? 1 : isTablet ? 1 : 1} sx={{marginTop:'15px'}}>
                {settings.mktDatasets.map((cake)=> (
                <Grid key={cake.cakeId} item lg={1} md={2} sm={3} className={classes.datasetListing} onClick={(e) => handleDatasetClick(e, cake.cakeId)}>
                  <Typography align='center' className='cursor-pointer'>{cake.title}</Typography>
                </Grid>
                ))}
              </Grid>
              {/* <FakeDataBrowseDatasets handleDatasetClick={handleDatasetClick}/> */}
              </>
            {/* } */}
          </Box>
              
          {selectedDataset && <MktDatasetDetailsModal
            open={isMktDatasetDetailsModalOpen}
            cake={selectedDataset}
            onClose = {() => {
              setIsMktDatasetDetailsModalOpen(false)
            }}
          />}

          <Box sx={{flex:'0 1 auto', border:'1px solid black'}}>
            This space intentionally left empty...
          </Box>
        </Box>
      </Box>
    </div>
  )
})

export default DataMarket
