import c from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { makeStyles } from '../../core/utils/theme'

interface IProps {
  href?: string
  className?: string
  [key: string]: any
}

const useStyles = makeStyles()((theme) => ({
  root: {
    cursor: 'pointer',
    borderRadius: '12px',
    background: theme.palette.primary.main,
    padding: '8px 16px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const ButtonLink: FC<PropsWithChildren<IProps>> = ({
  children,
  href = '#',
  className,
  ...rest
}) => {
  const { classes } = useStyles()

  return (
    <a className={c(classes.root, className)} href={href} {...rest}>
      {children}
    </a>
  )
}

export default ButtonLink
