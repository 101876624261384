import { observer } from 'mobx-react'
import { FC } from 'react'
import useSettings from '../../../hooks/useSettings'
import ContentModal from './ContentModal'
import ColumnDescriptions from '../../misc/ColumnDescriptions'

interface IProps {}
const SettingsModal: FC<IProps> = observer(() => {
  const settings = useSettings()

  const handleClose = () => {
    settings.setIsDescriptionsModalOpen(false)
  }


  return (
    <>
      <ContentModal
        open={settings.isDescriptionsModalOpen}
        title="Descriptions"
        onClose={handleClose}
      >
        <ColumnDescriptions />
      </ContentModal>
    </>
  )
})

export default SettingsModal
