import { Box, Typography, useMediaQuery } from '@mui/material'
import /*theme,*/ theme, { makeStyles } from '../../core/utils/theme'
import AvatarMenu from './AvatarMenu'
import { isEndUserView } from '../../core/utils/main'
import Logo from './Logo'
import useApp from '../../hooks/useApp'
import useSettings from '../../hooks/useSettings'
import IosShareIcon from '@mui/icons-material/IosShare';
import ShareModal from '../main/modals/ShareModal'
import { observer } from 'mobx-react'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    width:"100%",
    padding: '0rem ',
    margin: '0px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      height: '40px'
    },
  },
  h65: {
    height: '65px'
  },
  h130: {
    height: '130px'
  },
  title: {
    display:'block',
    textAlign:'center',
    marginTop:'9px'
  },
  shareIcon: {
    marginBottom: '5px',
  },
  clickable: {
    cursor:'pointer',
  }
}))

const Navbar = observer(() => {
  const { classes, cx } = useStyles()
  const app = useApp()
  const settings = useSettings()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const getTitle = () => {
    if (!app.cakeId && !app.title)
      return null
    return (
      <div style={{height:'60px', display:'block'}}>
        <span className="mt-[5px] h-[100%] inline">
          <Typography className="inline" variant="h4">
            <IosShareIcon onClick={()=>settings.setIsShareModalOpen(true)} className={`${classes.shareIcon} invisible`}/>
            &nbsp; 
            <span className="cursor-pointer" onClick={()=>{settings.setIsDatacakesModalOpen(true)}}>{app.title ? app.title : app.cakeId}</span>
            &nbsp;
            <IosShareIcon onClick={(e)=>{e.stopPropagation(); settings.setIsShareModalOpen(true)}} className={classes.shareIcon}/>

          </Typography>
        </span>
      </div>
    )
  }
  
  if (!isEndUserView()) {
    if (isMobile || isTablet) {
      return (
        <Box className={cx(classes.root, classes.h130)}>
          <ShareModal open={settings.isShareModalOpen} onClose={() => { settings.setIsShareModalOpen(false) }} />
          <Box className={classes.root}>
            <Box sx={{position:'absolute', top:'0px', right:'0px'}}><AvatarMenu /></Box>
            <Box sx={{position:'absolute', top:'0px', left:'0px'}}><Logo /></Box>
          </Box>
          <Box className={classes.title}>{getTitle()}</Box>
        </Box>
      )
    } else {
      return (
      <Box className={cx(classes.root, classes.h65)}>
        <ShareModal open={settings.isShareModalOpen} onClose={() => { settings.setIsShareModalOpen(false) }} />
        <Box sx={{position:'absolute', top:'0px', right:'0px'}}><AvatarMenu /></Box>
        <Box sx={{position:'absolute', top:'0px', left:'20px'}}><Logo /></Box>
        <Box className={cx(classes.title, classes.clickable)}>{getTitle()}</Box>
      </Box>
      )
  }
  } else {
    if (isMobile || isTablet) {
      return (
        <Box className={cx(classes.root, classes.h65)}>
          <Box className={classes.title}>
            {getTitle()}
          </Box>
        </Box>
        )
      } else {
        return (
          <Box className={cx(classes.root, classes.h65)}>
              <Box className={classes.title}>
                {getTitle()}
              </Box>
          </Box>
        )
    }    
  }
})

export default Navbar