import { Box, Button, Typography } from '@mui/material'
import Modal from 'react-modal'
// import cookies from 'js-cookie'
import useSettings from '../../../hooks/useSettings';
import { TourType } from '../../../core/types/source_service/IGuideTour';
import { observer } from 'mobx-react';

// Icon
import OpenInNewWindowIcon from '../../../assets/Svgs/action/open_in_new_24px.svg'
import CLOSE from '../../../assets/Svgs/navigation/close_24px.svg'
import useUser from '../../../hooks/useUser';

const visualDataSteps = [
  {
    target: '.sourceTableButton',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        You'll see the data in your recipe here. Click Config to add more data or give Cubie more instructions on how to use each column.
      </Typography>
    ),
    // content: "You'll see the data in your recipe here. Click Config to add more data or give Cubie more instructions on how to use each column.",
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.queryInputField',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        Ask Cubie to analyze your data. She won't answer questions that your data can't answer.
      </Typography>
    ),
    placement: 'bottom'
  }
]

const shareCakeSteps = [
  {
    target: '.shareCakeButton',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        When you share your datacake, anyone can get answers from your data without having to handle data itself!
      </Typography>
    ),
    placement: 'top',
    disableBeacon: true,
  },
  {
    target: '.copyUrlButton',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        Anyone with this URL can start asking questions. <br />
        <br />
        Contact us if you need more restrictive permissions.
      </Typography>
    ),
    placement: 'right'
  },
  {
    target: '.adminMenuItem',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        For the users you share your cake with, you can specify sample questions for them to try.
      </Typography>
    ),
    placement: 'left',
  }
]


const dashboardSteps = [
  {
    target: '.queryInputField',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        Get the graph or chart you want by asking Cubie a question about your data.
      </Typography>
    ),
    placement: 'bottom',
    disableBeacon: true,
  },
  {
    target: '.dashboardButton',
    content: (
      <Typography variant='body1' textAlign={'left'}>
        Save graphs and tables you like to an easily shareable dashboard. This feature is coming soon!
      </Typography>
    ),
    placement: 'right',
  }
]

const GuidedTourModal = observer(() => {
  const user = useUser()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  const tourSteps = {
    [TourType.VISUALIZATION]: visualDataSteps,
    [TourType.SHARE]: shareCakeSteps,
    [TourType.DASHBOARD]: dashboardSteps,
  }

  // Get reference to settings context
  const settings = useSettings();

  const initiateTour = (tour: TourType) => {
    settings.setIsGuideTourModalOpen(false);
    settings.reportTourClick(tour, user.userEmail || '')
    if (tour === TourType.NONE) {
      // navigate to the url and close the modal
      window.open('https://datacakes-ai.webflow.io/data-security');
    } else {
      console.log('Settings tour details');
      // Set tour type in setting context so we know which one to start next time
      settings.setTourDetails(tour, tourSteps[tour])
    }
  }

  const textMap = {
    [TourType.NONE]: "Understand how my data is kept private",
    [TourType.VISUALIZATION]: "Analyze and visualize data for myself",
    [TourType.SHARE]: "Share data in a ready-to-use format",
    [TourType.DASHBOARD]: "Create a dashboard of visualizations (for myself or others)",
  }

  return (
    <Modal
      isOpen={settings.isGuideTourModalOpen}
      onRequestClose={() => settings.setIsGuideTourModalOpen(false)}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-[1100] px-3 md:px-0'
      }
      className={`rounded static max-h-full max-w-full w-[600px] min-h-[368px] bg-white border border-black`}
    >
      <Box pt={1} className="flex flex-col h-full">
        <Box className="flex justify-end ">
          <Button className="" onClick={() => settings.setIsGuideTourModalOpen(false)}>
            <img src={CLOSE} />
          </Button>
        </Box>
        <Box className='px-6 pb-6'>
          <Typography variant='h5' className='mb-6'>
            {`Welcome to your first Datacake!`}
          </Typography>
          <Typography variant='h6' className='mb-4'>
            {`What’s your primary interest?`}
          </Typography>
          <Box className='flex flex-col gap-4 w-full'>
            <>
            { Object.keys(textMap).sort(() => 0.5 - Math.random()).map((x, i) => 
                <Button key={i} variant='outlined' onClick={() => initiateTour(x as unknown as TourType)} className='w-full px-[0.875rem] py-[0.625rem]'>
                  <Typography variant='body1' textAlign={'left'} className='text-wrap w-full' textTransform={'capitalize'}>
                    {textMap[x as unknown as TourType]}
                  </Typography>
                  {((x as unknown as TourType)==TourType.NONE) && <img src={OpenInNewWindowIcon} />}
                </Button>
            )}
            </>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
});

export default GuidedTourModal
