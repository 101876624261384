import { FC, PropsWithChildren } from 'react'
import { makeStyles } from '../../core/utils/theme'

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  main: {
    margin: '0px auto',
    width: '100%',
    height: '100%',
    flexGrow: 1,
    overflowX: 'hidden',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
    },
  },
}))

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.main}>{children}</main>
    </div>
  )
}

export default PageLayout
