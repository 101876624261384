import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, PropsWithChildren } from 'react'
import Modal from 'react-modal'
import theme, { makeStyles } from '../../../core/utils/theme'
import { Box, IconButton, useMediaQuery } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  modalCloseBtn: {
    position: 'absolute',
    right: '3px',
    top: '3px',
    padding: '0',
    margin:'0',
    width:'1.25rem',
    color: theme.palette.text.primary,
  },
  modalCloseIcon: {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.text.primary,
  },
}))

const modalStyles: ReactModal.Styles = {
  overlay: {
    background: 'transparent',
    backdropFilter: 'blur(8px)',
    display: 'flex',
    zIndex: 11,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    WebkitBackdropFilter: 'blur(8px)',
  },
  content: {
    color: 'var(--qbd-color-a1)',
    border: `2px solid ${theme.palette.primary.main}`,
    padding: 0,
    width: '70%', // @todo Make it 100% on mobiles.
    position: 'static',
    maxHeight: 'calc(100% - 110px)',
    background: `linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 1) 100%)`,
  },
}

interface IProps {
  open: boolean
  title: string
  onClose: () => void
  style?: Modal.Styles
  showCloseButton?: boolean
}
const ContentModal: FC<PropsWithChildren<IProps>> = ({
  children,
  open = false,
  onClose,
  title,
  style = modalStyles,
  showCloseButton = true,
}) => {
  const { classes } = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  if (modalStyles.content) {
    modalStyles.content.width = `${matches ? '95%' : '70%'}`
  }

  return (
    <Modal
      isOpen={open}
      contentLabel={title}
      onRequestClose={onClose}
      style={style}
      className={'settings-modal'}
      // Make sure to keep this value in sync with the animation duration in styles/base.css
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
    >
      <Box className="relative p-[1.5rem]">
      {showCloseButton && (
        <IconButton onClick={onClose} className={classes.modalCloseBtn} title="Close the Window">
          <FontAwesomeIcon
            className={classes.modalCloseIcon}
            icon={faXmark}
            title="Close the Window"
          />
        </IconButton>
      )}
      {children}
      </Box>
    </Modal>
  )
}

export default ContentModal
