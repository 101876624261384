import { createBrowserRouter } from 'react-router-dom'
import IndexPage from '../../components/pages/IndexPage'
import { APP_ROOT_PATH } from '../config/main'
import Dashboard from '../../components/pages/Dashboard'
import DataMarket from '../../components/pages/DataMarket'

const mainRouter = createBrowserRouter(
  [
    {
      path: '/',
      element: <IndexPage />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/datamarket',
      element: <DataMarket />
    }
  ],
  { basename: APP_ROOT_PATH },
)

export default mainRouter
