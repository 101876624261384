import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import theme, { makeStyles } from '../../../core/utils/theme'
import DataObjectEntries from './DataObjects'
import useApp from '../../../hooks/useApp'
import { observer } from 'mobx-react'
// import Models from './Models'
import useSettings from '../../../hooks/useSettings'
import JoinBetaModal from '../modals/JoinBetaModal'
import { TourType } from '../../../core/types/source_service/IGuideTour'
import { isEndUserView } from '../../../core/utils/main'
import Logo from '../../misc/Logo'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone'

const useStyles = makeStyles()((theme) => ({
  root: {
    marginLeft: '20px',
    marginRight: '20px',
    display: 'flex',
    height: '100%',
    width: '200px',
    maxWidth: '200px',
    flexDirection: 'column',
    position: 'relative',
  },
  innerContainer: {
    background: 'transparent',
    position: 'absolute',
    whiteSpace: 'nowrap',
    height: '100%',
    zIndex: 1,
    border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: '0.25rem',
    // borderLeft: '0px',
    // borderTopRightRadius: '0.25rem',
    // borderBottomRightRadius: '0.25rem',
    [theme.breakpoints.down('md')]: {
      // maxWidth: '19rem',
      background: 'white',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: '-1',
    },
  },
  coreTableBtn: {
    transform: 'rotate(-90deg)',
    zIndex: 0,
    position: 'absolute',
    top: '65.8px',
    right: '-123px',
    borderRadius: '0px',
    padding: '11px',
    width: '189px',
    textTransform: 'capitalize',
    height: '57px',
    // height: '29px',
    color: 'white',
    fontSize: '1.2rem',
    [theme.breakpoints.up('md')]: {
      visibility:'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      width: '109px',
      top: '29.19px',
      padding: '11px',
      fontSize: '0.7rem',
      height: '0px',
    },
    [theme.breakpoints.down(380)]: {
        width: '86px',
        top: '32.19px',
        right: '-53px',
        padding: '9px',
        fontSize: '0.7rem',
        height: '0px',
      },
    [theme.breakpoints.down(1921)]: {
      fontSize: '1.2rem',
      top: '46.16px',
      right: '-124px',
    },
  },

  modalBtn: {
    transform: 'rotate(-90deg)',
    zIndex: 0,
    position: 'absolute',
    top: '280.8px',
    right: '-123px',
    borderRadius: '0px',
    padding: '11px',
    width: '189px',
    textTransform: 'capitalize',
    height: '57px',
    // height: '30px',
    color: 'white',
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '109px',
      top: '29.19px',
      padding: '11px',
      fontSize: '0.7rem',
      height: '0px',
    },
    [theme.breakpoints.down(380)]: {
      width: '86px',
      top: '32.19px',
      right: '-53px',
      padding: '9px',
      fontSize: '0.7rem',
      height: '0px',
    },
    [theme.breakpoints.down(1921)]: {
      fontSize: '1.2rem',
      // width: '150px',
      top: '210.16px',
      right: '-105px',
    },
  },
  wrapperOuter: {
    width:'200px',
    height:'100%',
    display:'flex',
    fontSize: '0.9rem',
  },
  wrapperInner: {
    overflowX:'hidden',
    overflowY:'auto',
    textOverflow:'ellipsis',
    margin:'15px',
    display: 'block',
    flexGrow:'1',
    fontSize: '0.9rem',
  },
}))

const LeftSidebar: FC = observer(() => {
  const { classes } = useStyles()

  const app = useApp()
  const settings = useSettings()

  const [isSourceTableClosed, setIsSourceTableClosed] = useState(false)
  const [isModelClosed, setIsModelClosed] = useState(true)
  const [openJoinBeta, setOpenJoinBeta] = useState(false);

  const isNotHugeScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isHugeScreen = !isNotHugeScreen

  useEffect(() => {
    if (!app.cakeId)
      setIsSourceTableClosed(true)
    setSourceTabClass()
    if (app.coreDataObjects.length === 0) return
  }, [isSourceTableClosed, app.coreDataObjects])

  useEffect(() => {
    if (settings.tourType !== TourType.NONE) {
      setIsSourceTableClosed(true);
    }
  }, [settings.tourType, settings.guideTourSteps])

  function handleSourceTableTab() {
    setIsSourceTableClosed(!isSourceTableClosed)
    const elt = document.getElementById("srcTables")
    if (elt) elt.classList.toggle('-translate-x-full')

    if (!isModelClosed) {
      setIsModelClosed(true)
    }
  }

  function setSourceTabClass() {
    const elt = document.getElementById("srcTables")
    if (elt && (elt.classList.contains('-translate-x-full') != isSourceTableClosed))
      elt.classList.toggle('-translate-x-full')

  }


  // function handleModelTab() {
  //   setIsModelClosed(!isModelClosed)

  //   if (!isSourceTableClosed) {
  //     setIsSourceTableClosed(true)
  //   }
  // }
  if (isNotHugeScreen)
    return (
      <Box className="z-10 w-[40px]">
        <Tooltip title="View Source Tables" placement='right'>
          <TableChartTwoToneIcon className="cursor-pointer z-[1] xl:hidden lg:hidden md:visible sm:visible my-2 ml-2" onClick={handleSourceTableTab} />
        </Tooltip>
        
        <Box
          id="srcTables"
          className={`fixed ${isSourceTableClosed ? '-left-[240px]' : 'left-0'} top-0 z-30 h-screen w-[240px] -translate-x-full overflow-y-auto bg-white p-4 transition-transform`}>
          <JoinBetaModal open={openJoinBeta} onClose={() => setOpenJoinBeta(false)} />
          <Box className="fixed h-full w-full relative">
            <Box
              sx={{ position: 'relative', height: '100%' }}
            >
              <Box
                className={classes.innerContainer}
                // className={`${classes.innerContainer} relative`}
              >
                <Box className={`${classes.wrapperOuter} relative`}>
                  <KeyboardArrowLeftIcon className="cursor-pointer absolute top-1 right-1" onClick={handleSourceTableTab} />
                  <Box className={`${classes.wrapperInner}`}>
                    <Tooltip title="These are the tables Cubie can use to answer your questions" placement='right'>
                      <Typography variant='body2' style={{fontWeight:'bold'}}>
                      <TableChartTwoToneIcon className="cursor-pointer mx-2" onClick={handleSourceTableTab} />
                      Source Tables
                      </Typography>
                    </Tooltip>
                    <DataObjectEntries />
                  </Box>
                </Box>
              </Box>
            </Box>
            {isEndUserView() && (
          <Box sx={{ marginTop: '2rem', textAlign:'center' }}>
              <div>CUBIE is a tool made by:</div>
              <Logo />
              {/* <div className='flex'>
                <a href={MAIN_SITE_URL} className="no-underline mx-auto" target="_blank">
                  <img loading="lazy" src={logoHeader} alt="Datacakes Logo" className={'w-[200px]'} />
                </a>
              </div> */}
          </Box>
        )}

          </Box>
        </Box>
      </Box>
    )
  if (isHugeScreen)
    return (
    <>
      <Box className={classes.root}>
        {isNotHugeScreen && <TableChartTwoToneIcon onClick={handleSourceTableTab} />}
        <JoinBetaModal open={openJoinBeta} onClose={() => setOpenJoinBeta(false)} />
        <Box
          sx={{ position: 'relative', height: '100%' }}
        >
          <Box className={classes.innerContainer}>
            <Box className={classes.wrapperOuter}>
              <Box className={classes.wrapperInner}>
                <Tooltip title="These are the tables Cubie can use to answer your questions" placement='right'>
                  <Typography variant='body2' style={{fontWeight:'bold'}} >
                  <TableChartTwoToneIcon className='mx-2'/>
                  Source Tables
                  </Typography>
                </Tooltip>
                <DataObjectEntries />
              </Box>
            </Box>
          </Box>
        </Box>
        {isEndUserView() && (
          <Box sx={{ marginTop: '2rem', textAlign:'center' }}>
              <div>CUBIE is a tool made by:</div>
              <Logo />
              {/* <div className='flex'>
                <a href={MAIN_SITE_URL} className="no-underline mx-auto" target="_blank">
                  <img loading="lazy" src={logoHeader} alt="Datacakes Logo" className={'w-[200px]'} />
                </a>
              </div> */}
          </Box>
        )}
      </Box>
    </>
  )
})
        {/* {user.isAuthenticated() && !isEndUserView() && (
          <Box
            className={classes.innerContainer}
            style={{
              zIndex: isModelClosed ? 0 : 1,
              transform: `${isModelClosed
                ? 'translateX(-100%)'
                : 'translateX(0%)'
                }`,
            }}
          >
            <Button
              disableElevation
              variant="contained"
              // className={`${classes.modalBtn}  absolute md:right-[-124px] md:top-[380px] right-[-92px] lg:top-[265px] top-[350px]`}
              className={`${classes.modalBtn}  absolute w-[50%] p-[5%] md:w-[189px] lg:w-[189px] xl:w-[189px]
              right-[-92px] md:right-[-124px] top-[220px] md:top-[280px] lg:top-[280px]`}
              sx={{
                background: isModelClosed
                  ? 'transparent !important'
                  : `${theme.palette.primary.main} !important`,
                color: isModelClosed
                  ? `${theme.palette.text.primary} !important`
                  : 'white !important',
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              onClick={() => setOpenJoinBeta(true)}
            >
              <div className="absolute">Dashboard</div>
            </Button>
            <Box className={classes.wrapper}>
            </Box>
          </Box>
        )} */}


export default LeftSidebar