import { FC, useEffect, useState } from 'react'
import MainContent from './content/MainContent'
import LeftSidebar from './leftsidebar/LeftSidebar'
import { makeStyles } from '../../core/utils/theme'
import useSettings from '../../hooks/useSettings'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { observer } from 'mobx-react'
import { TourType } from '../../core/types/source_service/IGuideTour'
import Navbar from '../misc/Navbar'
import RightSidebar from './rightsidebar/RightSidebar'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    paddingBottom: '0.5rem',
    flexWrap: 'nowrap',
    transition: 'all 0.3s ease-in-out',
    // [theme.breakpoints.down('xs')]: {
    //   gap: 0,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   gap: 0,
    // },
    // [theme.breakpoints.down('md')]: {
    //   // flexDirection: 'column',
    //   overflow: 'visible',
    //   gap: '0',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   gap: '2rem',
    // },
  },
}))

const CubieAssistant: FC = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<any>([]);

  useEffect(() => {
    console.log('Guide Steps', settings.guideTourSteps);
    if (settings.guideTourSteps.length > 0) {
      setStepIndex(0);
      setRun(true);
      setSteps(settings.guideTourSteps);
    }
  }, [settings.guideTourSteps])

  const handleCallBack = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    console.log('Status', status);

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setStepIndex(0);
      setRun(false);

      // reset setting context
      settings.setTourDetails(TourType.NONE, []);

      // Open the modal again once the tour is complete for a selected step.
      // settings.setIsGuideTourModalOpen(true);

    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (settings.tourType === TourType.SHARE) {
        console.log('I am getting triggered', nextStepIndex);
        // If the tourType is share then open share modal
        if (nextStepIndex === 1) {
          settings.setIsShareModalOpen(true);
          setTimeout(() => {
            setStepIndex(nextStepIndex);
          }, 1000);
          return;
        } 
        if (nextStepIndex === 2) {
          settings.setIsShareModalOpen(false);
          document.getElementById('avatarMenu')?.click();
          setTimeout(() => {
            setStepIndex(nextStepIndex);
          }, 200);
          return;
        }
        document.getElementById('avatarMenu')?.click();
        setStepIndex(nextStepIndex);
      }
      
      if (settings.tourType !== TourType.SHARE && settings.tourType !== TourType.NONE) {
        setStepIndex(nextStepIndex);
      }
    }
  }

  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.mainContainer}>
        <LeftSidebar />
        <MainContent />
        <RightSidebar />
      </div>
      <Joyride
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        showProgress
        showSkipButton
        hideBackButton
        continuous
        scrollToFirstStep
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
        scrollOffset={300}
        callback={handleCallBack}
        styles={{
          options: {
            primaryColor: 'black',
            overlayColor: 'rgba(0, 0, 0, 0.35)',
            width: 292,
            zIndex: 1500
          },
          spotlight: {
            zIndex: 1500,
            position: 'absolute',
          },
          overlay: {
            zIndex: 1500,
          }
        }}
      />
    </div>
  )
});

export default CubieAssistant
