import { observer } from 'mobx-react'
import { FC, /*MouseEvent, */useEffect, useState } from 'react'
// import { CONNECT_URL } from '../../core/config/main'
import { APP_NAME } from '../../../../core/config/main'
import useSettings from '../../../../hooks/useSettings'
// import SecondaryButtonLink from '../misc/SecondaryButtonLink'
import { makeStyles } from '../../../../core/utils/theme'
import { Box, Typography, TextField, Grid } from '@mui/material'
import Loader from '../../../misc/Loader'
// import { hasOpenModalFlagInUrl } from '../../core/utils/main'
// import snowflakebtn from '../../assets/snowflake.png'
import { addDataObjects } from '../../../../core/services/source_service'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
  },
  innerContainer: {
    minHeight: '55vh',
    maxWidth: '72rem',
    padding: '2rem',
  },
  description: {
    fontWeight: 300,
  },
  loggedInContainer: {
    marginBottom: '1.25rem',
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2rem',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  loaderContainer: {
    height: '30px',
  },
  userEmail: {
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    width: '100%', // Ensure the container takes the full width
    display: 'flex',
    justifyContent: 'center', // Spread out buttons
    marginTop: 'auto', // Pushes the button container to the bottom
  },
}))

interface IProps {
}
const DataSourceManageSnowflake: FC<IProps> = observer(() => {
  const settings = useSettings()
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const [inputDatabase, setInputDatabase] = useState<string>('');
  const [inputSchema, setInputSchema] = useState<string>('');
  const [inputWarehouse, setInputWarehouse] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {

  }, [])

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value
    switch (event.target.id) {
      case "inputDatabase":
        setInputDatabase(v);
        break
      case "inputWarehouse":
        setInputWarehouse(v)
        break
      case "inputSchema":
        setInputSchema(v)
        break
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    const new_src = [{
      type: 'snowflake',
      params: {
        database: inputDatabase,
        schema: inputSchema,
        warehouse: inputWarehouse
      }
    }];
    try {
      const response = await addDataObjects(new_src)

      if (response.status === 'ok') {
        //settings.isLoading = false;
        setSuccess(true)
        setLoading(false)
        settings.fetchNeededforTables = true;
      } else {
        // Handle non-'ok' status
        setError('AddDataObjects failed: ' + (response || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error adding data sources:', error);
      setError('AddDataObjects failed: ' + (error || 'Unknown error'));
    }
  }

  return (
    <Box className={`${classes.root} SNOWFLAKE`}>
      <Box className={`${classes.innerContainer} `}>
        <Typography variant="body1" className={classes.description}>
          You can connect {APP_NAME} to a database on Snowflake that is publicly accessible or accessible with your credentials. Note that you
          must have the necessary permissions to create queries on the datasets.
        </Typography>
        <Box className='h-[40px]'></Box>
        <Box className='w-full h-full'>
          {error && <div style={{ color: 'red' }}>{String(error)}</div>}
          {/* Show the input elements */}
          <Grid container alignItems='center' justifyContent='center' columns={4} spacing={2} width="100%" className="w-full">
            <Grid item xs={1}><label htmlFor="inputDatabase">Database:</label></Grid>
            <Grid item xs={3}><TextField className='w-full' type='text' id="inputDatabase" value={inputDatabase} onInput={handleInput} /></Grid>
            <Grid item xs={1}><label htmlFor="inputSchema">Schema:</label></Grid>
            <Grid item xs={3}><TextField className='w-full' id="inputSchema" value={inputSchema} onInput={handleInput} /></Grid>
            <Grid item xs={1}><label htmlFor="inputWarehouse">Warehouse:</label></Grid>
            <Grid item xs={3}><TextField className='w-full' id="inputWarehouse" value={inputWarehouse} onInput={handleInput} /></Grid>
          </Grid>
          <Box className={classes.buttonContainer} style={{ marginTop: '20px', textAlign:'center' }}>
            {loading
              ? (<Box className={classes.loaderContainer}>
                  <Loader className={classes.loader} />
                </Box>)
              : success
              ? <Box>Uploaded Successfully</Box>
              : <button className='cursor-pointer border px-8 py-2 rounded-lg'
                  type="button"
                  disabled={!inputDatabase || !inputSchema || !inputWarehouse}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
            } 
          </Box>
        </Box>
      </Box>
    </Box>
  )
})

export default DataSourceManageSnowflake
