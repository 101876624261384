import { FC, ReactNode } from 'react'
import { Box, Typography, Button } from '@mui/material'
import Modal from 'react-modal'

// icon
import Loader from '../../misc/Loader';
import { getDataServiceIcon, retrieveSourceParam } from '../../../core/utils/main';
import { CONNECT_URL } from '../../../core/config/main';

interface IProps {
  open: boolean
  onClose: () => void,
  children?: ReactNode
}
const RedirectionConfirmationModal: FC<IProps> = ({ open = false, onClose }) => {

  const sourceParam = retrieveSourceParam();
  const source = getDataServiceIcon(sourceParam.toString());

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10'
      }
      className={`rounded static max-h-full max-w-full md:max-w-[932px] min-h-[576px] bg-white px-6 py-4 border border-black mx-4`}
    >
      <Box pt={1} className="flex flex-col h-full p-12">
        <Box className="flex flex-col flex-1 items-center justify-center">
          <Typography variant='h6' className='my-4' >{`Redirecting to ${sourceParam ?? 'BigQuery'} for authentication`}</Typography>
          <Loader className="w-[10rem] mb-6" />
          <img src={source} width={72} height={72} />
        </Box>
        <Box className="flex flex-col items-center justify-center">
          <Typography variant='body1' className='mb-4'>{`Since you are adding ${sourceParam ?? 'Google BigQuery'} as your main ingredient, we are redirecting you to login.`}</Typography>
          <Button onClick={() => {
            window.location.href = CONNECT_URL(sourceParam, `/?openModal=1&source=${sourceParam}`)
          }}
          className="rounded bg-primary text-white hover:bg-primary-hover">
            Okay, Got it
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default RedirectionConfirmationModal
