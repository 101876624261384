import { FC, ReactNode } from 'react'
import { Box, Typography, Button, Stack } from '@mui/material'
import Modal from 'react-modal'

interface IProps {
  open: boolean
  onClose: () => void,
  children?: ReactNode
}
const JoinBetaModal: FC<IProps> = ({ open = false, onClose }) => {

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      closeTimeoutMS={700}
      shouldCloseOnEsc={true}
      overlayClassName={
        'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10'
      }
      className={`static max-h-full max-w-full md:max-w-[600px] min-h-[230px] bg-white px-6 py-4 border border-black mx-4`}
    >
      <Box pt={1} className="flex flex-col h-full">
        <Box className="flex-1 ">
          <Typography variant='h5' className='mb-4'>Are you curious about the Dashboard?</Typography>
          <Typography variant="body2" color={'#5B6B6F'} className='leading-5 mb-4'>
            The Dashboard feature lets you view all the visualizations you have saved and refresh them at any time with the latest data. To gain access, find us on <a href="https://discord.gg/NabnUPUta4" target="_blank">Discord</a> or join our beta program <a href="https://www.datacakes.ai/contact">here</a>.
          </Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'end'} gap={2}>
          <Button onClick={() => {
            window.location.href = 'https://www.datacakes.ai/contact';
          }}>
            Join our beta
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default JoinBetaModal
