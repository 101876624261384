import { observer } from 'mobx-react'
import { FC, useEffect,  } from 'react'
import { Box, Button } from '@mui/material'
// import { makeStyles } from '../../../core/utils/theme'
import Modal from 'react-modal'

import useSettings from '../../../../hooks/useSettings'

import DataSourceManageCSV from './DataSourceManageCSV'
import DataSourceManageGoogleAds from './DataSourceManageGoogleAds'
import DataSourceManageSnowflake from './DataSourceManageSnowflake1'
import DataSourceManageBigQuery from './DataSourceManageBigQuery'
// import { IDataSource } from '../../../core/types/source_service/IDataSource'
import { hasOpenModalFlagInUrl, retrieveSourceParam } from '../../../../core/utils/main'
import useUser from '../../../../hooks/useUser'
import CLOSE from '../../../../assets/Svgs/navigation/close_24px.svg'

// const useStyles = makeStyles()(() => ({
//   contentBox: {
//     position: 'relative', // This is necessary for z-index to take effect
//     zIndex: 1200, // Example z-index value
//   },
//   loader: {
//     margin: '0.5rem auto',
//     display: 'block',
//     width: '10rem',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textAlign: 'center',
//   },
//   loaderContainer: {
//     marginTop: '0.5rem',
//     display: 'flex',
//     flexDirection: 'column',
//   },
//   loadingDescription: {
//     textAlign: 'center',
//     fontWeight: 300,
//     fontStyle: 'italic',
//     color: '#222',
//   },
// }))

interface IProps {}

const DataSourceManagerModal: FC<IProps> = observer(() => {
  const settings = useSettings()
  // const classes = useStyles()
  const user = useUser()
  let source = ''

  useEffect(() => {
    if (
      user.isAuthenticated() && hasOpenModalFlagInUrl() && (source=retrieveSourceParam())
    ) {
        if (source)
          settings.setIsDataSourceManagerModalOpen(true, source);
    }
  }, [])

  const getContent = () => {
      if (settings.dataSourceTypeBeingManaged) 
        source = settings.dataSourceTypeBeingManaged
      else
        source = retrieveSourceParam()

      console.log('Data Source', source);
      switch (source) {
        case 'csv':
          return <DataSourceManageCSV />
        case 'googleads':
          return <DataSourceManageGoogleAds />
        case 'snowflake':
          return <DataSourceManageSnowflake />
        case 'bigquery':
          return <DataSourceManageBigQuery />
        default:
          return <Box className='h-[30vh] w-[30vw]'></Box>
      }
  }

  const handleBackToDataSources = () => {
    settings.setIsDataSourceManagerModalOpen(false)
    settings.setIsDataSourcesModalOpen(true)
  }

  const handleBakeCake = () => {
    settings.setIsDataSourceManagerModalOpen(false)
    settings.setIsCreateDataRecipeModalOpen(true)
  }

  return (settings.isDataSourceManagerModalOpen &&
      <Modal
        isOpen={settings.isDataSourceManagerModalOpen}
        onRequestClose={() => settings.setIsDataSourceManagerModalOpen(false)}
        contentLabel={""}
        closeTimeoutMS={700}
        shouldCloseOnEsc={true}
        overlayClassName={
          'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10 px-4'
        }
        className={`static w-full md:w-10/12 xl:w-[70%] bg-white border border-black rounded`}
      >
        <Box className='relative'>
          <Box className="flex justify-end ">
            <Button className="absolute py-2 px-2 md:py-4 md:px-4" onClick={() => settings.setIsDataSourceManagerModalOpen(false)}>
              <img src={CLOSE} />
            </Button>
          </Box>
          {getContent()}
          <Box className="border w-full flex justify-between align-middle ">
            <div className='m-2 ml-4 inline-block align-middle cursor-pointer' onClick={handleBackToDataSources}>&lt; Manage Data Sources</div>
            {settings.dataSources && settings.dataSources.length > 0 && <div className='m-2 mr-4 cursor-pointer' onClick={handleBakeCake}>Bake a Datacake &gt;</div>}
          </Box>
          
        </Box>
      </Modal>
  )
})

export default DataSourceManagerModal