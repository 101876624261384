import { Box, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useLayoutEffect, } from 'react'
import theme, { makeStyles } from '../../../core/utils/theme'
import useApp from '../../../hooks/useApp'
import useSettings from '../../../hooks/useSettings'
import { SampleQuestions } from './SampleQuestions'
import { ProviderInformation } from './ProviderInformation'
import { isEndUserView } from '../../../core/utils/main'

const useStyles = makeStyles()(() => ({
  root : {
    marginLeft:'20px',
    marginRight:'20px',
    width:'200px',
    display: 'flex',
    flexDirection: 'column'
  },
  questionsContainer: {
    width: '100%',
    display: 'flex',
    flex: '0 1',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  sampleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const RightSidebar: FC = observer(() => {
  const app = useApp()
  const settings = useSettings()
  const { classes } = useStyles()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  // const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isNotHugeScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isHugeScreen = !isNotHugeScreen

  useLayoutEffect(() => {
    if (app.cakeId)
      settings
        .loadSampleQuestions(app.cakeId)
        .then(() => { })
        .catch((e) => console.log('Error in loading sample questions', e))
  }, [])

  if (!isEndUserView() && isNotHugeScreen)
    return (
      <Box className="z-10 w-[40px]">
        <ProviderInformation />
        <SampleQuestions />
      </Box>
    )
  if (!isEndUserView() && isHugeScreen)
    return (<Box className={classes.root}></Box>) // Add Sample Questions???
  if (isEndUserView() && isNotHugeScreen)
    return (
      <Box className="z-10 w-[40px]">
        <ProviderInformation />
        <SampleQuestions />
      </Box>
    )
  if (isEndUserView() && isHugeScreen)
    return (
      <Box className={`${classes.root}`}>
        <ProviderInformation />
        <div style={{height:'20px'}}></div>
        <SampleQuestions />
      </Box>
    )
})

export default RightSidebar
