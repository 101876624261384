import React, { useMemo } from 'react'
import { makeStyles } from '../../../core/utils/theme'
import { Box } from '@mui/material'

export interface HTMLChartProps {
  answerChartHtml: string | null
  isDashboard: boolean
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '500px',
    marginTop: '15px',

    [theme.breakpoints.down(1921)]: {
      width: '100%',
      height: '500px',
    },
  },
}))

const HTMLChart: React.FC<HTMLChartProps> = ({ answerChartHtml, isDashboard }) => {
  const { classes } = useStyles()

  const chartUrl = useMemo(() => {
    const file = new Blob([answerChartHtml as string], { type: 'text/html' })
    const url = window.URL.createObjectURL(file)
    return url
  }, [answerChartHtml])

  return (
    <Box className={classes.root} sx={[isDashboard ? {border:'none'} : {border:'1px solid black'} ]}>
      <iframe src={chartUrl} width={'100%'} height={'100%'} />
    </Box>
  )
}

export default HTMLChart
