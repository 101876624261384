import { sanitize } from 'isomorphic-dompurify'
import { observer } from 'mobx-react'
import { FC, useEffect, useRef } from 'react'
import { actionLogProfile } from '../../../core/config/dompurify'
import useApp from '../../../hooks/useApp'
import { makeStyles } from '../../../core/utils/theme'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexGrow: 1,
    flexDirection: 'column',
  },
  actionLogText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '0.25rem',

    '& > div': {
      padding: '0.25rem',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  actionLogThoughts: {
    display: 'flex',
    minHeight: '10rem',
    flexGrow: 1,
    flexDirection: 'column',
    overflowX: 'auto',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    padding: '1rem 0.75rem',
  },
}))

interface IProps {}
const ActionLogTableNewWindow: FC<IProps> = observer(() => {
  const { classes, cx } = useStyles()

  const app = useApp()
  const thoughtsDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (thoughtsDiv.current == null) {
      return
    }

    thoughtsDiv.current.scrollTop = thoughtsDiv.current.scrollHeight
  }, [app.thoughtsText])

  return (
    <div className={cx(classes.root, 'action-log')}>
      <div className={classes.actionLogText}>
        <div>Cubie's Thoughts</div>
      </div>

      <div
        ref={thoughtsDiv}
        dangerouslySetInnerHTML={{
          __html: sanitize(app.thoughtsText, actionLogProfile),
        }}
        className={classes.actionLogThoughts}
      />
    </div>
  )
})
export default ActionLogTableNewWindow
