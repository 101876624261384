import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button } from '@mui/material'
import { sanitize } from 'isomorphic-dompurify'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useEffect, useRef } from 'react'
import { actionLogProfile } from '../../../core/config/dompurify'
import { makeStyles } from '../../../core/utils/theme'
import useApp from '../../../hooks/useApp'

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: '0rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexGrow: '1',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: '0.25rem',
    padding: '0.25rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.25rem',
    },
  },
  done: {
    margin: '0',
    width: '100%',
    border: '0',
    padding: '0',
  },
  actionLogBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.25rem',
    outline: '0',
  },
  actionLogText: {
    padding: '0.25rem',
    fontWeight: 'bold',
    color: 'black',
  },
  actionLogDone: {
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: 'blue',
    textTransform: 'lowercase',
    padding: '0',
    margin: '0'
  },
  actionLogWindowIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0.25rem',
    fontSize: '1rem',
    color: theme.palette.primary.main,

    '& > svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  noPaddingMargin: {
    padding: '0px',
    margin: '0px'
  },
  actionLogThoughts: {
    display: 'flex',
    minHeight: '4rem',
    flexGrow: 1,
    flexDirection: 'column',
    overflowX: 'auto',
    overflowY: 'auto',
    padding: '1rem 0.75rem',
  },
}))

interface IProps {
  isInNewWindow?: boolean
}
const ActionLogTableMain: FC<IProps> = observer(() => {
  const { classes, cx } = useStyles()
  const app = useApp()
  const thoughtsDiv = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (thoughtsDiv.current == null) {
      return
    }

    thoughtsDiv.current.scrollTop = thoughtsDiv.current.scrollHeight
  }, [app.thoughtsText])

  return (
    <Box
      className={cx((app.isThinking || app.isActionLogOpenedInNewWindow) ? classes.root : classes.done, 'action-log')}
      sx={[
        app.isThinking && !app.isActionLogOpenedInNewWindow && { height: '100%', flexGrow: 1 },
        !app.isThinking && app.isActionLogOpenedInNewWindow && { padding: 0, margin: 0 }
      ]}
    >
      <Button
        className={classes.actionLogBtn}
        sx={[
          app.isActionLogOpenedInNewWindow
            ? { borderRadius: '0.25rem' }
            : { borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem' },
        ]}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          app.toggleActionLogOpenedInNewWindow()
        }}
        title={!app.isActionLogOpenedInNewWindow ? 'Open in New Window' : 'Close the New Window'}
      >
        <Box className={(app.isThinking || app.isActionLogOpenedInNewWindow) ? classes.actionLogText : classes.actionLogDone}>
        {(!app.isThinking && !app.isActionLogOpenedInNewWindow) && 'Review'} Cubie's Thoughts
        </Box>
        {(app.isThinking || app.isActionLogOpenedInNewWindow) ?
        <Box className={classes.actionLogWindowIcon}> 

          <FontAwesomeIcon
            icon={!app.isActionLogOpenedInNewWindow ? faExpand : faCompress}
            title={
              !app.isActionLogOpenedInNewWindow ? 'Open in New Window' : 'Close the New Window'
            }
          />
        </Box> : '...'
        }
      </Button>
      {(app.isThinking || app.isActionLogOpenedInNewWindow) && (
        <Box
          ref={thoughtsDiv}
          dangerouslySetInnerHTML={{
            __html: sanitize(app.thoughtsText, actionLogProfile),
          }}
          className={classes.actionLogThoughts}
          sx={[
            !app.isActionLogOpenedInNewWindow && {
              borderBottomRightRadius: '0.375rem',
              borderBottomLeftRadius: '0.375rem',
            },

            !app.isActionLogOpenedInNewWindow && !app.isThinking
              ? {
                  maxHeight: '1px',
                  display: 'none'
                }
              : { maxHeight: '100%' },
          ]}
        />
      )}
    </Box>
  )
})
export default ActionLogTableMain
