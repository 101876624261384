import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { FC, useRef } from 'react'
import NewWindow from 'react-new-window'
import { makeStyles } from '../../../core/utils/theme'
import useApp from '../../../hooks/useApp'
import ActionLogTableMain from './ActionLogTableMain'
import ActionLogTableNewWindow from './ActionLogTableNewWindow'

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  done: {
    margin: '0px'
  }
}))

interface IProps {}
const ActionLog: FC<IProps> = observer(() => {
  const { classes } = useStyles()

  const app = useApp()
  const newWindowRef = useRef(null)

  if (app.thoughtsText?.length == 0)
    return null
  return (
    <Box
      className={(app.isThinking || app.isActionLogOpenedInNewWindow) ? classes.root : classes.done }
      sx={[
        app.isThinking && !app.isActionLogOpenedInNewWindow && { height: '0vh', flexGrow: 1, overflow: 'auto' },
        !app.isThinking && !app.isActionLogOpenedInNewWindow && { padding:'0', margin:'0'},
        ]}
    >
      <ActionLogTableMain />

      {app.isActionLogOpenedInNewWindow && (
        <NewWindow
          ref={newWindowRef}
          features={{
            left: '20%',
            top: '20%',
            width: 1000,
            height: 600,
          }}
          onUnload={() => app.closeActionLogOpenedInNewWindow()}
          title="Cubie's Thoughts"
          name="Cubie's Thoughts"
        >
          <ActionLogTableNewWindow />
        </NewWindow>
      )}
    </Box>
  )
})

export default ActionLog
