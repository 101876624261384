import { useEffect, useState } from 'react'
import useSettings from '../../hooks/useSettings'
// import useUser from '../../hooks/useUser'
import PageLayout from '../layouts/PageLayout'
import CubieAssistant from '../main'
import { hasTourFlagInUrl } from '../../core/utils/main'
import CreateDataRecipeModal from '../main/modals/CakeBaking/CreateDataRecipeModal'
import { Box } from '@mui/material'
import { observer } from 'mobx-react'
// import { hasConfirmationFlagInUrl } from '../../core/utils/main'

const IndexPage = observer(() => {
  // const user = useUser()
  const settings = useSettings();
  const tour: boolean = hasTourFlagInUrl();
  const [dots, setDots] = useState<string>('')

  useEffect(() => {
    doDots(0)
    // Show guided tour if we have tour value in url
    if (tour) {
      console.log('Open guide tour modal');
      settings.setIsGuideTourModalOpen(true);
    }
  }, [tour])

  const doDots = async (i:number) => {
    setTimeout(() => {
      setDots('.'.repeat(i))
      doDots((i+1)%4)
    }, 250)
  }

  return (
    <PageLayout>
      {settings.awaitingState ? (
        <Box className="flex flex-col h-full w-full justify-middle justify-center">
          <div className="text-center">
            <span className="invisible">{dots}</span>
            <span className="">Loading Cubie</span>
            <span className="">{dots}</span>
          </div>
        </Box>
      ) : (
        <>
          <CubieAssistant />
          <CreateDataRecipeModal />
        </>
      )}
    </PageLayout>
  )
})

export default IndexPage
