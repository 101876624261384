import { FC, useEffect, MouseEvent, useState } from 'react'
import PageLayout from '../layouts/PageLayout'
import  { makeStyles } from '../../core/utils/theme'
import { Grid, Box } from '@mui/material'
import { observer } from 'mobx-react'
import useApp from '../../hooks/useApp'
import Navbar from '../misc/Navbar'
import { IDashboardItem } from '../../core/types/code_service/IDashboardItem'
import AnswerSection from '../main/content/AnswerSection'
import { useNavigate } from 'react-router-dom'
import { isEndUserView } from '../../core/utils/main'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useUser from '../../hooks/useUser'
import Loader from '../misc/Loader'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatIcon from '@mui/icons-material/Chat';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  mainContainer: {
    display: 'flex',
    flexDirection:'column',
    width:'100%',
    // height: '500px',
    overflow: 'hidden',
    
    paddingBottom: '0.5rem',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    transition: 'all 0.3s ease-in-out',

    gap: '3rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      overflow: 'visible',
    },
  },
  pickerbox: {
    textAlign:'center'
  },
  picker: {
    color:'grey',
    '&:hover':{
      color:'black',
      cursor:'pointer'
    }
  },

  backlink: {
    marginLeft: '14px',
    position:'absolute',
    top:'0.5rem',
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      padding:'0',
      left:'0'
    }
  }
}))

const timestr = (s: string) => {
  return s.slice(0,19).replace('T', '  ')
}

const DashboardItems: FC = observer(() => {
  const app = useApp()
  const user = useUser()
  const dashboardItems = app.dashboardItems
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  useEffect(() => {
    app.loadDashboardItems(app.cakeId, setIsLoading)
  }, [])

  const rerunAll = async (e: MouseEvent<HTMLElement>) => {
    setIsLoading(true)
    const element = e.target as HTMLElement
    element.innerText = 'Running...'
    const r = await app.runAllDashboardItems(app.cakeId, element, setIsLoading)
    console.log('r', r)
    element.innerText = 'Done'
    setTimeout(() => {
      element.innerText = 'Re-run ALL analyses'
    }, 3000)
  }

  const rerun = async (e: MouseEvent<HTMLElement>, query_id: string) => {
    const element = e.target as HTMLElement
    element.innerText='Running...'  
    const r = await app.runDashboardItem(app.cakeId, query_id, element)
    console.log('r', r)
    app.loadDashboardItems(app.cakeId, setIsLoading)
  }
  
  const handleDeleteDashboardItem = async (query_id: string) => {
    const r = await app.deleteDashboardItem(app.cakeId, query_id)
    console.log('r',r)
    app.loadDashboardItems(app.cakeId, setIsLoading)
  }

  return (
    <Box className="items-center overflow-y-scroll" sx={{width:'100%'}}>
      <Box className="h-[30px] flex justify-center align-middle">
        {isLoading && <Loader className="mt-1 w-[150px]"/>}
        {!isLoading && app.dashboardItems && app.dashboardItems.length > 0 &&
          <span className="mt-2 cursor-pointer" onClick={(e) => rerunAll(e)}>Re-run ALL analyses</span>
        }
      </Box>
      {(app.dashboardItems.length > 0)
        ? (
          <Box>
            <Grid container alignItems='center' justifyContent="center">
            {dashboardItems.map((item: IDashboardItem) => (
              <Box className="relative" key={item.query_id}>
              {(user.isInsider() || !isEndUserView() || app.cakeId == 'nhanes_fd') && <FontAwesomeIcon
                className="absolute top-6 left-6 cursor-pointer opacity-10 hover:opacity-100 z-20"
                icon={faXmark}
                onClick={() => handleDeleteDashboardItem(item.query_id)}
                />
              }
                <Grid item className="m-5 overflow-hidden relative p-1" sx={{width:'408px', height:'408px', border:'1px solid black'}}>
                  <Box className="absolute bottom-0 left-0 right-0 bg-opacity-100" sx={{width:'100%'}}>
                    <Box className="text-black text-xs italic pb-1 pl-2 mr-1 float-left z-20" 
                    >
                      Last run {timestr(item.saved_at)}
                    </Box>
                    <Box onClick={(e) => rerun(e, item.query_id)} className="float-right cursor-pointer pr-2 pb-1 text-xs underline float-right z-50">
                      Re-run analysis
                    </Box>
                  </Box>
                  <Box sx={{overflowY: 'scroll', scale:'67%', width:'604px', paddingX:'2px', height:'550px', position:'relative', top:'-89px', left:'-102px'}}>
                    {item.answer && 
                      <AnswerSection
                        answerStr={item.answer.answer} 
                        answerData={item.answer.data}
                        answerChartData={item.answer.chart_data}
                        answerChartHtml={item.answer.chart_html || null}
                        isDashboard={true}/>
                    }
                  </Box>
                </Grid>
              </Box>
              ))}
            </Grid>
            {/* <span className="absolute bottom-20 right-10 cursor-pointer opacity-100 background-inherit" onClick={(e) => handleShare(e)}>Share Dashboard</span> */}
          </Box>
        ) : (
          <Box className="w-[50vw] m-auto">
            The Dashboard is where you can view saved analyses and visualizations, update them on new data, and share the results with others.
            <br/><br/>
            When Cubie answers a question, just hit the "Save to Dashboard" button and the answer will appear here. It's that simple. 
          </Box>
        )
      }
    </Box>
  )
})


const Dashboard: FC = () => {
  const { classes } = useStyles()
  const app = useApp()
  const navigate = useNavigate()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleDashboardClick = () => {
    navigate(isEndUserView() ? `/?cakeId=${app.cakeId}` : '/' )
  }
    
  return (
    <PageLayout>
      <div className={`${classes.root}`}>
        <Navbar />

        <div className={classes.mainContainer}>
          <Box className={classes.pickerbox}>
            <DashboardIcon /> <b>Dashboard</b>
            &nbsp; &nbsp; | &nbsp; &nbsp; 
            <span className={classes.picker} onClick={handleDashboardClick}>
              <ChatIcon /> Ask Cubie
            </span>
          </Box>
          <DashboardItems  />
        </div>
      </div>
    </PageLayout>
  )
}

export default Dashboard
