import { Box, Button, /*Grid, */ Typography } from '@mui/material'
import { sanitize } from 'isomorphic-dompurify'
import { observer } from 'mobx-react'
import { FC, MouseEvent, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { IDataObject } from '../../../core/types/code_service/IDataObject'
import theme, { makeStyles } from '../../../core/utils/theme'
import useApp from '../../../hooks/useApp'
import Loader from '../../misc/Loader'
import { isEndUserView } from '../../../core/utils/main'
import useUser from '../../../hooks/useUser'
import useSettings from '../../../hooks/useSettings'
import DataViewModal from '../modals/DataViewModal'
import DataObjectHeadModal from '../modals/DataObjectHeadModal'

const useStyles = makeStyles()((theme) => ({
  loader: {
    width: '100px',
    display: 'block',
  },
  contentRoot: {
    maxWidth: '32rem',
    background: 'transparent',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  sourceRoot: {
    paddingLeft: '0.5rem',
    paddingTop: '0.5rem',

    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      borderRadius: 4,
    },
  },
  sourceName: {
    display: 'block',
    whiteSpace: 'nowrap', // truncate text
    overflow: 'hidden', // // truncate text
    textOverflow: 'ellipsis',
    maxWidth: '100%', // or set a specific width according to your layout
  },
  sourceHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '0.5rem',
  },
  infoIcon: {
    display: 'flex',
    height: '1.25rem',
    width: '1.25rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
    minWidth: '0%',
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.secondary,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent:'end'
  },
  bottomContainer: {
    paddingTop: '20px',
    display: 'flex',
    flex: 0,
    flexDirection: 'column'
  },
}))

const DataObjectEntries: FC = observer(() => {
  const { classes } = useStyles()
  const app = useApp()
  const settings = useSettings()
  const user = useUser()
  let srcTableMap = Object()

  if (!app.cakeId) {
    console.log("No cake ID")
    return
  }
  app.coreDataObjects.forEach((src) => {
    if (!srcTableMap[src.source])
      srcTableMap[src.source] = [src]
    else
      srcTableMap[src.source].push(src)
  })

  return (
    <Box
      sx={{
        flexDirection: 'column',
        height:'fit-content'
      }}
    >
      <Box className={classes.contentContainer}>
        {app.isInitializing && <Loader className={classes.loader} />}

        {Object.keys(srcTableMap).map((srcType: string, i: number) => {
          const srcs = srcTableMap[srcType]
          return (
            <Box key={i} sx={{ marginTop: '12px' }}>
              <Typography variant='body2' fontWeight={'bold'}>{srcType === 'duckdb' ? 'csv' : srcType}</Typography>
              {srcs.map((s: IDataObject) => {
                console.log('LeftSidebar table', s.name)
                return <DataObjectEntry key={s.name} source={s} />
              })}
            </Box>
          )
          })}
      </Box>
      {user.isAuthenticated() && !isEndUserView() && (
        <Box className={classes.bottomContainer}>
          {app.cakeId && (
            <Button variant='outlined' onClick={() => settings.setIsDescriptionsModalOpen(true)} className='uppercase text-xs'>
              Edit Descriptions
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
})
export default DataObjectEntries

interface IDataObjectEntryContent {
  isSessionSource?: boolean
  source: IDataObject
  className?: string
}
const DataObjectEntryContent: FC<IDataObjectEntryContent> = ({
  source,
  className,
  isSessionSource = false,
}) => {
  const { classes, cx } = useStyles()
  
  return (
    <Box className={cx(classes.contentRoot, className)}>
      <Box>
        <Typography component={'span'} className={classes.bold} sx={{fontSize: '0.85rem', lineHeight: '1.25rem'}}>
          Name:
        </Typography>{' '}
        {sanitize(source.name)}
      </Box>

      <Box>
        <Typography component={'span'} className={classes.bold} sx={{fontSize: '0.85rem', lineHeight: '1.25rem'}}>
          {!isSessionSource ? 'Description' : 'Generating Question'}:
        </Typography>{' '}
        {sanitize(!isSessionSource ? source.description : source.q || '')}
      </Box>

      <Box>
        <Typography component={'span'} className={classes.bold} sx={{fontSize: '0.85rem', lineHeight: '1.25rem'}}>
          Columns:
        </Typography>{' '}
        {source.columns?.map((column: any) => column.name).join(', ')}
      </Box>
          
      {source.num_rows && (
        <Box>
          <Typography component={'span'} className={classes.bold} sx={{fontSize: '0.85rem', lineHeight: '1.25rem'}}>
            # Rows:
          </Typography>{' '}
          {Number(source.num_rows)}
        </Box>
      )}
    </Box>
  )
}

interface IDataObjectEntry {
  source: IDataObject
  className?: string
  isSessionSource?: boolean,
  isModelsSource?: boolean
}
export const DataObjectEntry: FC<IDataObjectEntry> = ({ source, className, isSessionSource = false, isModelsSource = false }) => {
  const { classes, cx } = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const hasHead = source.sample && source.sample?.columns != null

  return (
    <Box className={cx(classes.sourceRoot, className)}>
      <Box
        data-tooltip-id={source.name}
        className={classes.sourceName}
        sx={[
          hasHead && {
            cursor: 'pointer',
          },
        ]}
        onClick={hasHead ? handleClick : undefined}
      >
        {source.name}
      </Box>
      {hasHead && (
        <>
          {!isModelsSource && <DataObjectHeadModal
            head={source.sample}
            limit={isEndUserView() ? 10 : -1} // -1 is used to not enforce the limit condition
            title={`Sample (limited rows) of ${source.name}`}
            open={isModalOpen}
            onClose={handleModalClose}
          />}
          {isModelsSource && <DataViewModal
            head={source.sample}
            title={`Sample (limited rows) of ${source.name}`}
            open={isModalOpen}
            onClose={handleModalClose}
          />}
        </>
      )}
      <Tooltip
        id={source.name}
      >
        <DataObjectEntryContent isSessionSource={isSessionSource} source={source} />
      </Tooltip>
    </Box>
  )
}

interface IDataObjectEntryHeader {
  tooltipId: string
  title: string
  hintText: string
}
export const DataObjectEntryHeader: FC<IDataObjectEntryHeader> = ({ title, hintText, tooltipId }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.sourceHeaderRoot}>
      <Box className={classes.bold}>{title}</Box>
      <Button data-tooltip-id={tooltipId} className={classes.infoIcon}>
        <Typography component={'span'} sx={{ pt: 0.2 }}>
          ?
        </Typography>
      </Button>
      <Tooltip
        id={tooltipId}
        style={{
          zIndex: 11,
          background: theme.palette.primary.main,
          color: theme.palette.text.secondary,
          whiteSpace: 'pre-wrap',
        }}
      >
        {hintText}
      </Tooltip>
    </Box>
  )
}
