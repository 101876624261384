import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import { Box, Button, Stack, Step, StepLabel, Stepper, TextField, Typography, useMediaQuery } from '@mui/material'
import theme, { makeStyles } from '../../../../core/utils/theme'
import Modal from 'react-modal'
import StepSelectTables from './StepperPanels/StepSelectTables'
import StepDescribeColumns from './StepperPanels/StepDescribeColumns'
import { warning } from '../../../../core/services/alerts'

import {
  createLocalTableLink,
} from '../../../../core/utils/table_setup'
import { ITableLinkColumnLocal } from '../../../../core/types/source_service/ITableLinkColumnLocal'
import useSettings from '../../../../hooks/useSettings'
import { ITable } from '../../../../core/types/source_service/ITable'
import StepLinks from './StepperPanels/StepLinks'

// Icon
import CLOSE from '../../../../assets/Svgs/navigation/close_24px.svg'
// import EXCLAMATION from '../../../../assets/Svgs/alert/error_24px.svg'
import SELECTALL from '../../../../assets/Svgs/action/select_all_24px.svg';
import DESELECTALL from '../../../../assets/Svgs/action/deselect_all_24px.svg'
import { areArraysEqual } from '../../../../core/utils/main'
import { IDataRecipe } from '../../../../core/types/source_service/IDataRecipe'
// import TableSetupStepSelectDatabaseCSV, { TableSetupStepSelectDatabaseCSVRef } from '../../../table_setup/TableSetupStepSelectDatabaseCSV'
// import TableSetupStepSelectDatabaseGoogleAds from '../../../table_setup/TableSetupStepSelectDatabaseGoogleAds'
// import TableSetupStepSelectDatabaseSnowflake from '../../../table_setup/TableSetupStepSelectDatabaseSnowflake1'
import useApp from '../../../../hooks/useApp'
// import { getDefaultCakeIdFromCookies } from '../../../../core/utils/main.ts'


// import { error } from '../../../../core/services/alerts'

const steps = [
  { id: 1, label: 'Datacake Name', subLabel: 'Create a new Datacake' },
  { id: 2, label: 'Select Tables', subLabel: '[0] Tables selected' },
  { id: 3, label: 'Describe Columns', subLabel: 'Select & describe each column' },
  { id: 4, label: 'Identify Join Keys', subLabel: 'Add a connection between Tables' },
]

const stepDescription = [
  "",
  "Select the tables that you want Cubie to access. This version of Cubie is limited to 10 tables. Please contact us if you'd like Cubie to handle more.",
  "Select the columns that you want Cubie to access. You can edit the table/column descriptions suggested by Cubie if needed to improve performance.",
  "Tell Cubie how your tables should be connected (joined), if any connections exist. Each connection is between two tables and should specify a column (or 'key') from each table."
]

const useStyles = makeStyles()(() => ({
  customSteps: {
    '& .MuiStepLabel-alternativeLabel': {
      color: '#0A191C',
    },
  },
  contentBox: {
    position: 'relative', // This is necessary for z-index to take effect
    zIndex: 1200, // Example z-index value
  },
  descriptionContainer: {
    minWidth: '10%', // Adjust as needed
  },
  loader: {
    margin: '0.5rem auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}))

interface IProps { }

const CreateDataRecipeModal: FC<IProps> = observer(() => {
  // const csvTableSetupRef = useRef<TableSetupStepSelectDatabaseCSVRef>(null);

  const { classes } = useStyles();
  const settings = useSettings();
  const app = useApp();
  const [dataProfile, setDataProfile] = useState('')
  const [stepLabels, setStepLabels] = useState(steps); // function for this state: setStepLabels
  const [activeStep, setActiveStep] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [localLinks, setLocalLinks] = useState<(ITableLinkColumnLocal | null)[][]>([
    createLocalTableLink(null, null),
  ])

  const [localColumns, setLocalColumns] = useState<ITableLinkColumnLocal[]>([])
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (settings.isCreateDataRecipeModalOpen) {
      setActiveStep(0);
      setIsSelectAll(false);
      setLocalLinks([createLocalTableLink(null, null)]);
      setLocalColumns([]);

      // disable overflow when the open is open
      document.body.style.overflow = 'hidden';

      settings
        .loadDataObjects()
        .then(() => {
          console.log('Data sources are loaded from CreateDataRecipeModal');
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else document.body.style.overflow = 'auto';
  }, [settings.isCreateDataRecipeModalOpen])

  const checkIfTableDetailsNeedsFetch = () => {
    const areEqual = areArraysEqual(
      settings.initialDataObjects.filter((source) => source.is_selected),
      settings.dataObjects.filter((source) => source.is_selected),
    )

    settings.fetchNeededforTableDetails = !areEqual
  }

  const selectAllColumns = () => {
    settings.sourceTables.forEach((table: ITable) => {
      table.columns.forEach((column: ITableColumn) => {
        if (column.is_selected && !isSelectAll) {
          return
        }

        settings.toggleTableColumn(table.id, column.name)
      })
    })

    setIsSelectAll(!isSelectAll)
  }

  const handleFinish = async () => {
    setLoadingSubmit(true)

    const localLinksFilter = localLinks.filter((link) => link.every(Boolean))

    settings.updateTableLinks(localLinksFilter)
    app.removeOpenModalParam()

    const payload: IDataRecipe = {
      cake_id: null,
      name: '',
      title: '',
      max_rows: 0,
      recipe: [],
      sample_questions: []
    }

    payload.name = dataProfile;

    await settings.createRecipe(payload, () => {
      console.log('Is Triggered from Admin', settings.isCreateRecipeTriggeredFromAdmin);

      settings.setIsSettingsModalOpen(false)
      // app.setIsWelcomeModalOpen(false)
      app.updateSources()
      settings.loadRecipesByCurrentUser()

      setStepLabels(steps);
      settings.setIsCreateDataRecipeModalOpen(false, false);

      const hasTakenTour = Boolean(localStorage.getItem('tour'));

      if (!hasTakenTour) {
        // Open Guide Tour Modal
        settings.setIsGuideTourModalOpen(true);
        localStorage.setItem('tour', "true");
      }
    })
  }

  const handleNext = () => {
    if (activeStep === 1) {
      setIsSelectAll(false);
      checkIfTableDetailsNeedsFetch();
    }

    const labels = [...stepLabels];
    if (activeStep === 0) {
      // if(dataProfile === '') {
      //   error('Please enter a name for your Data Profile')
      //   return;
      // }
      labels[activeStep].subLabel = dataProfile ?? steps[activeStep].subLabel;
    }

    if (activeStep === 1) {
      const tableCount = settings.dataObjects.filter((source) => source.is_selected).length.toString();
      const rightBraketIndex = labels[activeStep].subLabel.indexOf(']');
      labels[activeStep].subLabel = steps[activeStep].subLabel.substring(0, 1) + tableCount + steps[activeStep].subLabel.substring(rightBraketIndex);
    }

    setStepLabels(labels)

    setActiveStep(activeStep + 1)
  }

  const getStepDescription = () => {
    return stepDescription[activeStep];
  }

  const getTableContent = () => {
    return (
      <Box className={`ABCDE flex-1 rounded w-full h-[400px] lg:h-[600px] xl:h-[400px] ${activeStep === 2 ? 'p-0 h-[35vh]' : 'p-4'} ${activeStep === 3 && 'h-[300px]'} ${activeStep !== 0 && 'border border-[#0A191C1F]'}`}>
        <Box className={`overflow-y-auto ${(settings.isLoading || settings.isLoadingAiDescriptions) ? `${activeStep == 2 ? 'h-[75%]' : 'h-[90%]'}` : 'h-full'}`}>
          <Box className="flex items-center justify-center">
            <TextField
              label="Name"
              variant="filled"
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: '#f0f2f2',
                },
                '& .MuiInputLabel-root': {
                  color: '#5B6B6F',
                  fontSize: 14,
                },
              }}
              className='w-full lg:w-[60%]'
              defaultValue={dataProfile}
              onChange={(e) => {
                setDataProfile(e.target.value)
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13)
                  handleNext()
              }}
              onSubmit={handleNext}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return <StepSelectTables />
      case 2:
        return <StepDescribeColumns />
      case 3:
        return <StepLinks loadingSubmit={loadingSubmit}
          localLinks={localLinks}
          updateLocalLinks={setLocalLinks}
          localColumns={localColumns}
          setLocalColumns={setLocalColumns} />
      default:
        return getTableContent()
    }
  }

  const handleManageDataSources = () => {
    settings.setIsCreateDataRecipeModalOpen(false)
    settings.setIsDataSourcesModalOpen(true)
  }
  return (
    <>
      <Modal
        isOpen={settings.isCreateDataRecipeModalOpen}
        onRequestClose={() => settings.setIsCreateDataRecipeModalOpen(false, false)}
        contentLabel={""}
        closeTimeoutMS={700}
        shouldCloseOnEsc={true}
        overlayClassName={
          'backdrop-blur-[8px] fixed top-0 h-full w-full flex flex-col justify-center items-center z-10 px-4'
        }
        className={`static w-full md:w-10/12 xl:w-[70%] bg-white border border-black rounded`}
      >
        <Box className={classes.contentBox}>
          <Box className="flex justify-end ">
            <Button className="py-2 px-2 md:py-4 md:px-4" onClick={() => settings.setIsCreateDataRecipeModalOpen(false, false)}>
              <img src={CLOSE} />
            </Button>
          </Box>
          <Box className="p-3">
            <Stepper activeStep={activeStep} alternativeLabel >
              {stepLabels.map((item: { id: number; label: string, subLabel: string }, index: number) => (
                <Step key={index} completed={index <= activeStep}>
                  <StepLabel className={classes.customSteps}>
                    <Typography variant="h6" className={`${isMobile && 'text-base leading-tight'}`} fontWeight={"500"}>{item.label}</Typography>
                    {index <= activeStep && <Typography variant="caption" className={`${isMobile && 'leading-tight'}`} fontWeight={"400"}>{item.subLabel}</Typography>}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box id="StepperContent" className="min-h-64 md:min-h-[42vh] mt-4 md:mt-12 flex flex-row mx-0 lg:mx-8 gap-6 flex-grow">
              <Box className={`flex-col max-w-[30%] hidden md:flex ${classes.descriptionContainer}`}>
                <Box className='flex-col max-w-[80%] hidden md:flex'>
                  {getStepDescription() ? (
                    <Typography variant='subtitle2' className='leading-6 mt-10 text-[#0A191C]'>
                      {getStepDescription()}
                    </Typography>
                  ) : (
                    <div style={{ minWidth: '100%' }}> {/* Ensure the div takes up the entire width */}
                      {/* Optionally render a non-breaking space or placeholder */}
                    </div>
                  )}
                </Box>
              </Box>
              {getStepContent()}
            </Box>
            <Box className="flex flex-1 w-full justify-end">
              {activeStep==0 && <div className='cursor-pointer flex items-center' onClick={handleManageDataSources}>Add/manage data sources</div>}
              <Stack direction={`${isMobile ? 'column' : 'row'}`} gap={3} className='mx-3 lg:mx-8 mb-6 mt-6 w-full lg:w-[64%]'>
                <Box className={`${activeStep !== 2 && 'hidden'}`}>
                  <Button onClick={selectAllColumns}>
                    <img src={isSelectAll ? DESELECTALL : SELECTALL} width={20} height={20} className='mr-2' />
                    {`${isSelectAll ? 'Deselect All Columns' : 'Select All Columns'}`}
                  </Button>
                </Box>
                <Box className={`flex justify-end items-center gap-4 flex-1 relative`}>
                  {activeStep==1 && <div className='cursor-pointer absolute left-5' onClick={handleManageDataSources}>Add/manage data sources</div>}
                  <Button variant="outlined" className={activeStep==0 ? "invisible" : ""} onClick={() => {
                    if (activeStep === 0) return;

                    if (activeStep === 3) {
                      settings.setCameBackFromLinks(true)
                    }

                    setActiveStep(activeStep - 1)
                  }}>
                    Back
                  </Button>
                  <Button variant="outlined" className='rounded bg-primary text-white hover:bg-primary-hover font-bold '
                    onClick={() => {
                      if (activeStep === 0) {
                      }
                      if (activeStep === 1) {
                        if (!settings.hasSelectedTables()) {
                          warning('Please select at least one table.')
                          return
                        }
                      }
                      if (activeStep === 2) {
                        if (!settings.hasSelectedColumns()) {
                          warning('Please select at least one column.')
                          return
                        }
                      }
                      if (activeStep === 3) {
                        handleFinish().then(() => { }).catch((e) => console.log('Error in saving profile', e));
                        return
                      }

                      handleNext();
                    }}>
                    <Typography variant='button' className=''>{`${activeStep === 3 ? 'Submit config' : 'Next'}`}</Typography>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
})

export default CreateDataRecipeModal
