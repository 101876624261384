import theme, { makeStyles } from '../../../core/utils/theme'
import { Box, Button, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import useSettings from '../../../hooks/useSettings'
import { error } from '../../../core/services/alerts'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import { observer } from 'mobx-react'
import useApp from '../../../hooks/useApp'

const useStyles = makeStyles()(() => ({
  staticContainer: {
    backgroundColor: '#fff',
    height: 'fit-content',
    display: 'flex',
    justifyContent:
      'center',
    flexDirection: 'column',
    borderColor: 'black',
    borderWidth: 1,
    // borderRadius: '0.25rem',
    // minHeight: 325,
    maxHeight: 350,
    padding: '1rem',
  },
  innerContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // paddingLeft: 18,
    // paddingRight: 18,
    fontSize: 12,
    overflowY: 'auto',
  }
}))

// interface IProviderInfo {
//   name: string, 
//   tagline: string, 
//   contact: string, 
//   logo: string, 
//   website: string
// }

export const ProviderInformation = observer(() => {
  const { classes } = useStyles()
  const settings = useSettings()
  const app = useApp()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isNotHugeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (settings.isProviderInfoEmpty())
      settings.loadProviderInformation(app.cakeId)
    toggleNav()
  }, [settings.isProviderInfoEmpty])

  const toggleNav = () => {
    const menu = document.getElementById('providerInfo')
    if (menu) menu.classList.toggle('-translate-x-full')
  }

  const handleError = () => {
    setShowLogo(false);
    const logo = document.getElementById('logo');
    if (logo) {
      logo.style.display = 'none';
    }
  }

  if (!settings.isProviderInfoEmpty())
    return null
  if (isNotHugeScreen)
    return (
      <>
        <Tooltip title="Cake Info" placement='left'>
          <InfoTwoToneIcon className="cursor-pointer z-[100] xl:hidden lg:hidden md:visible sm:visible my-4" onClick={toggleNav} />
        </Tooltip>
        <div
          id="providerInfo"
          className="fixed -right-[240px] top-0 z-30 h-screen w-[240px] -translate-x-full overflow-y-auto bg-white p-4 transition-transform"
          aria-labelledby="drawer-label"
        >
          <KeyboardArrowRightIcon className="cursor-pointer absolute top-1 left-1" onClick={toggleNav} />

          <ul className="flex flex-col items-center gap-3">
            <Tooltip title="Cake Info" placement='left'>
              <InfoTwoToneIcon className="cursor-pointer z-[100] xl:hidden lg:hidden md:visible sm:visible" onClick={toggleNav} />
            </Tooltip>
            <Box className={`${classes.innerContainer} ${!settings.providerInfo?.logo && 'justify-center'}`}>
              {settings.providerInfo?.logo && settings.providerInfo?.logo !== 'None' && showLogo && (
                <Box id='logo' className='h-24 mx-4 mt-4'>
                  <img src={settings.providerInfo?.logo} alt="" onError={handleError} className='object-contain w-auto h-full mx-auto' />
                </Box>
              )}
              <Typography textAlign={'center'} variant='h6' fontWeight={'bold'}>{settings.providerInfo ? settings.providerInfo.name : ''}</Typography>
              <Typography sx={{ maxWidth: 300, marginTop: '1rem', maxHeight: (isMobile || isTablet) ? '100%' : 250, whiteSpace: 'pre-line' }} variant='body2' textAlign={'center'}>{settings.providerInfo ? settings.providerInfo.tagline : ''}</Typography>
            </Box>
            <Button variant='outlined'
              onClick={() => {
                if (settings.providerInfo) {
                  window.open(settings.providerInfo.website, '_blank')
                } else error('No website is registered with the server');
              }}
              className={`${(isMobile || isTablet) && 'mt-8'}`}>
              Website
            </Button>
          </ul>
        </div>
      </>
    )
  else
    return (
      <Box className={classes.staticContainer}>
        {settings.providerInfo?.logo && settings.providerInfo?.logo !== 'None' && showLogo && (
          <Box id='logo' className='h-24 mx-4'>
            <img src={settings.providerInfo?.logo} alt="" onError={handleError} className='object-contain w-auto h-full mx-auto' />
          </Box>
        )}
        <Typography textAlign={'center'} variant='h6' fontWeight={'bold'} style={{ padding: '10px' }}>{settings.providerInfo ? settings.providerInfo.name : ''}</Typography>
        <Box className={`${classes.innerContainer} mb-2 ${!settings.providerInfo?.logo && 'justify-center'}`}>
          <Typography sx={{ whiteSpace: 'pre-line' }} variant='body2' textAlign={'center'}>{settings.providerInfo ? settings.providerInfo.tagline : ''}</Typography>
        </Box>
      </Box>
    )
})
